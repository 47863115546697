<template lang="pug">
input(v-model='query' type='text' :placeholder='placeholder')
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    autoSearch: {
      type: Boolean,
      default: true,
    },
    updateRoute: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      routerTimeout: null,
    }
  },
  setup() {
    return {
      navigateTo,
    }
  },
  computed: {
    query: {
      get() {
        return this.$searchContext.state.value.searchParameters.query
      },
      set(val) {
        // Trigger the search so we can have instant results.
        this.$searchContext.setQuery(val)

        if (this.autoSearch) this.$searchContext.search()

        if (!this.updateRoute) return this.$emit('query', val)

        // We don't actually use this as were we use this sets the update prop to false but
        // If we set then we want to clear the previous iteration
        clearTimeout(this.routerTimeout)

        // Debounce the router stuff so we don't DDOS the router.
        this.routerTimeout = setTimeout(() => {
          const { mod } = this.$route.query

          const routeObj = {
            name: this.$route.name,
            params: {
              ...this.$route.params,
              // noUpdateScroll: true,
            },
            query: {
              ...(mod && { mod }),
              ...this.$searchContext.createRouteQuery(),
            },
          }

          this.navigateTo(routeObj)
        }, 1000)
      },
    },
  },
})
</script>
